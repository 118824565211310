import React, { useState, useEffect } from 'react';

const loadingMessages = [
  "Searching for top products...",
  "Gathering information...",
  "Finding the best options...",
  "Analyzing reviews...",
  "Almost there...",
  "Compiling results..."
];

const Loader = ({ isLoading }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    if (!isLoading) return;

    const intervalId = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [isLoading]);

  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
        <p className="text-lg font-medium">{loadingMessages[messageIndex]}</p>
      </div>
    </div>
  );
};

export default Loader;